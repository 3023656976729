.card{
    background-color: #0D0D0D !important;
    border: 1px solid rgba(142, 178, 116, 0.7);
    .cardHeading{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #FCFCFC;
    }
    .DiagnosiscardContent{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #A0A0A0;
        padding: 20px 40px 20px 40px;
        @media (max-width: 767px) {
            padding: 16px;
        }
    }
}

.cardbgMate{
    background-color: #242424 !important;
}