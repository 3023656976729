.medicalNeedContainer{
    background: #0D0D0D;
    .medicalNeedHeading{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        @media (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.5px;
        }
    }
    .medicalNeedSubHeading{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #FCFCFC;
        margin-top: 10px;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .medicalNeedDescription {
        font-family: Rubik;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #FCFCFC;
        @media (max-width: 767px) {
            font-size: 20px;
            line-height: 150%;
        }
    }
    .medicalSubDesription {
        color: #A0A0A0;
        font-size: 20px;
        font-family: Rubik;
        line-height: 150%;
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .divider {
        border-bottom: 1px solid rgba(142, 178, 116, 0.70);
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .medicalDetailsDiv {
        border: 1px solid #F2E854;
        background: #1A1A1A;
        border-radius: 40px;
    }
    .surveillance-text {
        color:#F2E854;
        font-size: 50px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        @media (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.5px;
        }
    }
    .surveillance-subtext {
        color:#FCFCFC;
        font-size: 20px;
        font-family: Rubik;
        line-height: 150%;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            margin-top: 20px;
        }
    }
    .focus-diseases-text {
        color:#F2E854;
        font-size: 30px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 1.2px;
        }
    }
    .sources-acs-text {
        color:#545454;
        font-size: 14px;
        font-family: Rubik;
        line-height: 20px;
        margin-top: 40px;
        margin-bottom: 50px;
        @media (max-width: 767px) {
            font-size: 12px;
            line-height: 16px;
        }
    }
    .daignosisName{
        padding: 15px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #F2E854;
        @media (max-width: 767px) {
            color:#FCFCFC;
            font-size: 24px;
            font-family: Rubik;
            font-weight: 700;
            line-height: 150%;
        }
    }

    .diagnosisImage{
        padding: 15px;
    }
    .diagnosisHeadings{
        border-bottom: 1px solid #8EB274;
    }
}
.syntax{
    .count{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FCFCFC;
        @media (max-width: 767px) {
            font-size: 24px;
            font-family: Rubik;
            line-height: 150%;
        }
    }
}
.Diagnosisdetails{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        margin-top: 4px;
    }
}
.analysisDiv{
    background: #0D0D0D;
    border-top: 0px !important ;
    border-left: 0px !important ;
    border-right: 0px !important ;
}
.chartDiv {
    padding: 40px 50px;
    border-radius: 40px;
    .chart-heading {
        color: #FCFCFC;
        font-size: 16px;
        font-family: Rubik;
        font-weight: 700;
        line-height: 22px;
        @media (max-width: 767px) {
            font-size: 14px;
            font-family: Rubik;
            line-height: 20px;
        }
    }
    .source-subheading {
        color:#545454;
        font-size: 14px;
        font-family: Rubik;
        line-height: 20px;
        @media (max-width: 767px) {
            font-size: 10px;
            font-family: Rubik;
            line-height: 16px;
        }
    }
}
.overUseHeading{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FCFCFC;
    @media (max-width: 767px) {
        font-size: 24px;
        font-family: Rubik;
        font-weight: 700;
        line-height: 150%;
    }
}

