.contact-heading {
  width: 394px;
  height: 60px;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1.5px;
  }
}

.formLabel {
  width: 97px;
  height: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #fcfcfc;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.formFeild {
  width: 507px;
  height: 56px;
  background-color: #333333 !important;
  border: 0px solid #ced4da !important;
  border-radius: 4px;
  margin-top: 8px;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.formCheckbox {
  background-color: transparent;
  border: 1px solid #fff;
}

.checkboxText {
  height: 22px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  color: white !important;
  a {
    @media (max-width: 767px) {
      display: contents;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.link-text {
  color: #8eb274 !important;
  text-decoration: underline;
}

.contactUsButton {
  max-width: 507px;
  width: 100%;
  height: 56px;
  background: #f2e854 !important;

  .buttonText {
    width: 41px;
    height: 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0d0d0d !important;
    text-wrap: nowrap;
  }
}

.detailsCard {
  background: #242424 !important;

  .cardSpanText {
    width: 44px;
    height: 22px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #f2e854;
  }
}

.form-layer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  @media (max-width: 767px) {
    background: rgba(0, 0, 0, 0.8);
  }
}

.cancerImageDetails {
  background: url("../../images/theme/nationalCancerInstitute.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: inset 0px -125px 96px 0px #0d0d0d;
}

.formError {
  height: 20px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fb0000;

  .warning-img {
    width: 13.55px !important;
    height: 13.55px !important;
  }
}

.requiredAstricks {
  margin-left: 2px;
  color: #fb0000 !important;
}

.contact-form {
  z-index: 3;
  padding-top: 60px;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    position: relative;
    padding-top: 40px;
  }
}

// .img-gradient {
//     position:relative;
//   }

//   .img-gradient::after {
//     content: '';
//     position: absolute;
//     left: 0; top: 0;
//     width: 100%; height: 100%;
//     background: linear-gradient(180deg, #F2E854 0%, #378091 100%);
//   }

.layer {
  background: linear-gradient(180deg, #f2e854 0%, #378091 100%);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: color;
}

.contactUsHeading {
  padding-bottom: 60px !important;
}
