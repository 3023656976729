.footerContainer {
  background-color: #0d0d0d;
  border-top: 1px solid rgba(142, 178, 116, 0.7);

  .newsLetter {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fcfcfc;
  }

  .btn-subscribe {
    background-color: #f2e854;
    border-radius: 4px;
    margin-left: 20px;
    width: 140px;
    height: 56px;
  }

  .btn-subscribe-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0d0d0d !important;
  }

  .copyRight {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #a0a0a0;
  }

  .copyRightContent {
    border-top: 1px solid #545454;
  }

  .joinNewsLetterGrid {
    padding-top: 40px;
    .newsLetterText {
      color: #F2E854;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .formLabel {
      width: 100%;
    }
    .formFeild {
      width: 100%;
    }
    .contactUsButton {
      max-width: unset;
      border-radius: 4px;
    }
  }

  .joinNewsLetterGrid-mobile {
    @media (max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
      border-bottom: 1px solid #545454;
      .subcribe-news {
        color: #FCFCFC;
        font-family: Rubik;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        a {
          color: #8EB274;
        }
      }
    }
  }

  .navBarTextYellow {
    text-decoration: none !important;
    color: yellow !important;
  }

  .navLink {
    text-decoration: none !important;
    color: white;
    @media (max-width: 767px) {
      margin: 10px 0;
    }
  }
  .col-span-8 {
    @media (min-width: 767px) and (max-width: 991px) {
      .col-span-8 {
        grid-column: span 12 / span 12;
      }
    }
    @media (max-width: 767px) {
      margin-left: 35px;
    }
  }
}


.footer{
  background-color: $grey-100;
  padding:4rem 5rem 2rem;

  .footer-menu{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2rem;

    .nav{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;

      a{
        color: $white;
        &:hover{
          color: $yellow-20;
        }
        &.selected{
          color: $yellow-50;
        }
      }
    }

    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      gap: 40px;
      img{
        width: 200px;
      }
      .nav{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    
  }

  @media (max-width: 991px) {
    border-top: 1px solid $green-50;
  

.join-to-newsletter-redirect{
  padding: 40px 1rem;
  border-top: 1px solid $green-50;
  border-bottom: 1px solid $green-50;
  text-align: center;
}

  @media (max-width: 1280px) {
      padding: 2rem 3rem;
  }
  }

  div:has(> .copy){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .copy{
      color: $grey-40;
    }
    .legal{
      display: flex;
      flex-direction: row;
      gap: 20px;
    }

    @media (max-width: 991px) {
      border-top: 1px solid $green-50;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 40px;
      padding: 40px 0;

      .legal{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}