.home-page{
  .home-wrapper{
    position: relative;
    width: 100%;
    height: 100vh;
  
    video{
      position: absolute;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 0;
      object-fit: cover;
  
      @media screen and (min-width: 991px){
        width: 100%;
      }
    }
  
    .home-content{
      position: relative;
      height: 100vh;
      z-index: 1;
  
      @media screen and (max-width: 991px){
        h1, p{
          text-align: center;
        }
      }
    }
  }
  .mision-vision-wrapper{
    background-color: $black;
    gap: 60px;
    padding-top: 80px;
    padding-bottom: 40px;

    div:has(img){
      width: 520px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    @media screen and (max-width: 991px){
      flex-direction: column-reverse;
      div:has(img){
        width: 100%;
      }
    }
  }

  .solution-wrapper{
    position: relative;
    background-image: url("../../images/backgrounds/solution-landing.webp");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 100%;

    &::before{
      content: "";
      position: absolute;
      top: 0;
      background: linear-gradient(180deg,$grey-100, transparent);
      width: 100%;
      height: 48px;
    }

    &::after{
      content: "";
      position: absolute;
      background: linear-gradient(0deg,$grey-90, transparent);
      width: 100%;
      height: 48px;
      bottom: 0;
    }
  }
  .solution-card{
    padding: 38px 30px;
    background-color: $grey-90;
  }

  .email-wrapper{
    background-color: $grey-90;
    gap: 20px;
  }
}