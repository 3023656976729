.px-page {
  padding-left: 5rem;
  padding-right: 5rem;

  @media screen and (max-width: 991px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (max-width: 767px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.p-page {
  padding: 10rem 6.25rem;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media screen and (max-width: 991px) {
    padding: 4rem 1rem;
  }
}

.border-down {
  box-shadow: inset 0 -2px 0 0 #8eb274b3;
}

/* text styles */

h1 {
  font-size: 100px;
  line-height: 100px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5%;

  @media screen and (max-width: 991px) {
    font-size: 34px;
    line-height: 46px;
  }
}
h2 {
  font-size: 50px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5%;

  @media screen and (max-width: 991px) {
    font-size: 30px;
    line-height: 40px;
  }
}
h3 {
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5%;

  @media screen and (max-width: 991px) {
    font-size: 30px;
    line-height: 40px;
  }
}
h4 {
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5%;

  @media screen and (max-width: 991px) {
    font-size: 30px;
    line-height: 40px;
  }
}
h5 {
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5%;

  @media screen and (max-width: 991px) {
    font-size: 24px;
    line-height: 30px;
  }
}

h6 {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 5%;

  @media screen and (max-width: 991px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.text-big {
  font-size: 24px;
  line-height: 150%;
  font-weight: 700;
}
.text-description {
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
}
b,
.text-bold {
  font-weight: 700;
}
p,
.text-regular {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 20px;
  }
}
.caption {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  line-height: 5%;

  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 16px;
    line-height: auto;
  }
}

.text-gradient {
  background: $gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-yellow {
  color: $yellow-50;
}

.text-grey{
  color: $grey-40;
}

.text-red {
  color: $red;
}

/* Button styles */
.btn {
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  /* text-bold */
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.btn.cta {
  width: 300px;
  background-color: $yellow-50;
  color: $grey-100;

  &:hover {
    background-color: $yellow-20;
    color: $grey-100;
  }
  &:active {
    background-color: $yellow-60;
    color: $grey-100;
  }
  &:disabled {
    background-color: $yellow-10;
    color: $grey-40;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}
.btn.simple {
  padding: 1rem;
}
.btn.lg-btn {
  width: 300px;
  padding: 1rem;
}

.link-text {
  color: $green-50;
  text-decoration: underline;

  &:hover {
    color: $green-60;
  }
}

/* common styles */
ul {
  list-style: disc;
  padding-left: 1rem;

  li::marker {
    color: $yellow-50;
  }
}

input{
  width: 100%;
}

label{
  margin-bottom: 8px;

  &:has( ~ input:required)::after{
    content: "*";
    color: $red;
  }
}

.nav-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: $white;

  &:hover {
    color: $yellow-50;
  }
  &.selected {
    color: $yellow-50;

    &::before {
      content: ".";
      position: absolute;
      top: 1rem;
    }
  }
}

.popover-menu {
  position: absolute;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  background-color: $grey-50;
  border-radius: 6px;
  padding: 6px;
  z-index: 10;

  a {
    width: 100%;
    padding: 10px 12px;
    color: $white;
    border-radius: 4px;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      background-color: $grey-60;
    }
  }
}

input,
textarea {
  border-radius: 4px;
  padding: 14px 1rem;
  background-color: $grey-70;
  color: $white;
  border: 2px solid transparent;
  outline: none;

  &::placeholder {
    color: $grey-40;
  }
  &:focus {
    border-color: $green-50;
  }
}

.informationCard {
  background-color: #242424 !important;
  .cardDateDetails {
    height: 20px;
    font-family: "Rubik", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a0a0a0;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .cardDetailStyling {
    padding: 40px 20px 40px 20px;
  }
  .details {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #fcfcfc !important;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.7px;
    }
  }
  .detailLong {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fcfcfc;
  }
}

/* clinical paradigm cards */
.clinical-paradigm-card {
  padding: 20px 40px;
  background-color: $grey-90;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: $grey-40;
  transition: background-color 0.3s ease;

  .card-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }
  }

  .content {
    flex: 1;
  }

  @media screen and (max-width: 991px) {
    flex-direction: row;
    padding: 16px;
    gap: 16px;

    .card-image {
      width: 64px;
      height: 64px;

      img {
        width: 64px;
        height: 64px;
        object-fit: cover;
      }
    }
  }

  &:hover {
    background-color: $grey-80;
  }
}
