.termscontainer{
    background: #0D0D0D !important;
    padding-top: 120px;
}

.followingText{
    font-weight: 400;
    color:#FCFCFC;
    font-size: 20px;
    font-family: Rubik;
    line-height: 150%;
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
    }
}

.termsBorder{
    .terms-policy-heading {
        font-size: 50px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        @media (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 1.5px;
        }
    }
}

.bg-yellow {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: #F2E854;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.7px;
    }
}
.textItem {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color:#FCFCFC;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
}