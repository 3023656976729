.heading {
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  height: 60px;
}

.dateAndLocation {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #a0a0a0;
}

.articleDetail {
  background-color: #242424 !important;
  margin-bottom: 100px;
  margin-top: 60px;
  margin-left: 100px !important;
  margin-right: 100px !important;

  .detailDate {
    color: #a0a0a0 !important;
  }

  .articleLongText {
    color: #fcfcfc;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.formFeildControl {
  border: 1px solid #f8f2a0 !important;
  border-radius: 4px;
}

.heading2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1.5px;
  }
}

.inputFeild {
  outline-style: none;
}

.paginationNumber {
  width: 12px;
  height: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #ffffff;
}

.paginationDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  img {
    cursor: pointer;
  }
  span {
    width: 1ch;
    text-align: center;
  }
}

.pressReleaseContainer {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  background: #0d0d0d;
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.btn.articleSearchButton {
  background-color: #f2e854;
  border-radius: 4px;
  border: 1px solid #f2e854;

  .articleButtonSearchText {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0d0d0d;
  }
}

.resultsFound {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #a0a0a0;
}

.noMatches {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  height: 400px;
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    color: #bababa;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #a0a0a0;
  }
}
