.header.blurred {
  background-color: #0d0d0da8 !important;
  backdrop-filter: blur(8px);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #00000000;
  transition: all 300ms ease;

  .desktop-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 5rem;
    gap: 2rem;

    .header-logo {
      width: 310px;
    }

    .navbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;
    }
  }
  .mobile-menu {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem;

    .header-logo {
      width: 128px;
    }

    .mobile-menu-wrapper {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem 1rem;
      gap: 24px;
      z-index: 1000;
      background-color: $grey-100;

      .header-menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .nav-menu {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;

        .nav-link {
          font-size: 24px;
          line-height: 30px;
          text-transform: uppercase;
          font-weight: 700;
          width: max-content;

          &:hover {
            background: $gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .popover-menu {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          background-color: $grey-100;
          border-radius: none;
          border: none;
          padding: 1rem;
          gap: 20px;
          a {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .desktop-menu {
      padding: 2rem 3rem;
      .header-logo {
        width: 200px;
      }

      .navbar {
        gap: 16px;
      }
    }
  }

  @media (max-width: 991px) {
    .mobile-menu {
      display: flex;
    }
    .desktop-menu {
      display: none;
    }
  }
}
