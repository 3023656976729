$white: #FFFFFF;
$black: #000000;

$grey-30: #BABABA;
$grey-40: #A0A0A0;
$grey-50: #545454;
$grey-60: #3D3D3D;
$grey-70: #333333;
$grey-80: #242424;
$grey-90: #1A1A1A;
$grey-100: #0D0D0D;

$yellow-10: #FBF9D0;
$yellow-20: #F8F2A0;
$yellow-50: #F2E854;
$yellow-60: #EEE225;

$green-20: #C4D7B7;
$green-50: #8EB274;
$green-60: #759E57;

$red: #FB0000;

$gradient: linear-gradient(90deg, #F2E854, #378091 );