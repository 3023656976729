.immunisSolutionContainer {
    background: #0D0D0D;

    .immusisHeading {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        @media (max-width: 767px) {
            font-size: 30px;
            line-height: 40px;
            text-align: left;
            letter-spacing: 1.5px;
        }
    }

    .immunisSubHeading {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        color: #FCFCFC;
        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            text-align: left;
            margin-top: 10px;
        }
    }

    .immunisAiDetailsCard {
        padding: 30px 20px;
        border: 1px solid #F2E854;
        min-height: 100%;
        background-color: #1A1A1A;
        border-radius: 40px;

        .card-number-text {
            font-size: 60px;
            font-family: Montserrat;
            font-weight: 700;
            line-height: 80px;
            letter-spacing: 3px;
            text-transform: uppercase;
            background: -webkit-linear-gradient(#378091, #F2E854);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .ImmunisDetails {
            font-family: 'Rubik';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #FFFFFF;
        }
    }

    .targetApproachText {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #F2E854;
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 1.2px;
            text-align: left;
            margin-bottom: 30px;
        }
    }

    .personalized-disease-container {
        background-image: url("../../images/png/personlized_section_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        .disease-card {
            padding: 30px;
            background: #1A1A1A;
            margin-bottom: 20px;
            .block-heading {
                color: var(--white, #FCFCFC);
                font-size: 16px;
                font-family: Rubik;
                font-weight: 700;
                line-height: 22px;
                margin-bottom: 20px;
            }
            .block-description {
                .list-dot {
                    padding: 3px;
                    background: #F2E854;
                    border-radius: 50px;
                    margin-right: 10px;
                }
                .block-description-content {
                    color:#A0A0A0;
                    font-size: 16px;
                    font-family: Rubik;
                    line-height: 22px;
                    @media (max-width: 767px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    .boxes-wrapper {
        padding-top: 80px;
        padding-bottom: 60px;
        .active-surveillance-heading {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            text-align: left;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #F2E854;
            @media (max-width: 767px) {
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 1.2px;
                text-align: left;
                margin-bottom: 30px;
            }
        }
        .box-card {
            border: 1px solid rgba(142, 178, 116, 0.70);
            padding: 30px;
            .count-digit {
                color: #FCFCFC;
                font-size: 24px;
                font-family: Rubik;
                font-weight: 700;
                line-height: 150%;
            }
            .number-info {
                color: #A0A0A0;
                font-size: 16px;
                font-family: Rubik;
                line-height: 22px;
                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    .quality-test-section {
        background: #1A1A1A;
        padding: 70px;
        text-align: center;
        @media (max-width: 767px) {
            padding: 100px 8px;
        }
        .quality-immunis-heading {
            font-family: Montserrat;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px;
            letter-spacing: 2px;
            text-transform: uppercase;
            @media (max-width: 767px) {
                font-size: 27px;
                line-height: 36px;
                letter-spacing: 1.5px;
                margin-bottom: 20px;
            }
        }
        .sub-heading {
            font-family: Rubik;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            color: #FCFCFC;
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 20px;
            }
        }
    }
}