.articlecontainer {
    background-color: #0D0D0D !important;
}

.articleContantCont {
    max-width: 65%;
    margin: 0 auto;
    @media (max-width: 767px) {
        max-width: 100%;
    }
}

.rightarrow {
    ul li {
        display: inline-flex;
        color: #F2E854;
    }
}

.text {
    color: #A0A0A0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.articleText {
    border-bottom: 2px solid #545454;
    padding-bottom: 20px !important;
}

.immunistext {
    color: #A0A0A0;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.evalutiontext {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #F2E854;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FCFCFC;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.subtitle {
    color: #F2E854;
    font-size: 30px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-top: 50px;
    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 1.2px;
    }
}

.keyFindingStep {
    ul li:before {
        content: "\2022";
        /* Unicode character for a bullet point */
        color: #F2E854;
        /* Change this to the desired color */
    }
}

.positiontext {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #FCFCFC;
    padding-left: 140px;
}

.positiontextmargin {
    padding-left: 140px;
}
.text-gray{
    color: #A0A0A0;
}

.text-center{
    text-align: center;
}

.underlined{
    text-decoration: underline;
}

.quote{
    border-left: 4px solid #F2E854;
    padding-left: 20px;
    display: grid;
    gap: 20px;
}

.attribution::before{
    content: "―";
}

.Aboutcard {
    padding: 30px;
    background-color: #1A1A1A;
}

.AboutcardHeader {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #545454;
}

.previousArticle {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #545454;
}

.articleStockImage {
    background-color: #0D0D0D !important;

}

.immunisHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.blueRibbonImage {
    width: 100%;
}