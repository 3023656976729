@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@368;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./common/variables";
@import "./pages/header";
@import "./pages/footer";
@import "./pages/landingPage";
@import "./pages/newsInformation";
@import "./pages/contactUs";
@import "./pages/articleDetail";
@import "./pages/termOfUse";
@import "./pages/contactUsCard";
@import "./pages/diagnosisCard";
@import "./pages/liquidBiopsyPipeline";
@import "./pages/aboutLeadership";
@import "./pages/leadershipCard";
@import "./pages/immunisAiSolution";
@import "./pages/medicalNeed";
@import "./common/common";

@layer base {
  * {
    outline: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  ::-moz-selection {
    /* Code for Firefox */
    color: $white;
    background: rgba(242, 231, 84, 0.25);
  }

  ::selection {
    color: $white;
    background: rgba(242, 231, 84, 0.25);
  }

  body,
  html {
    font-family: "Rubik", sans-serif !important;
    background-color: $grey-100;
    color: $white;
  }

  #root {
    overflow: hidden !important;
  }

  .navbar-link {
    &:hover {
      color: #f2e854;
    }
  }

  .font-Montserrat {
    font-family: "Montserrat", "sans-serif" !important;
  }

  .layer {
    background: linear-gradient(180deg, #f2e854 0%, #378091 100%);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: color;
  }
}
