.pipelineHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1.5px;
    }
}

.pipelineContainer {
    background: #0D0D0D !important;
    padding-left: 23%;
    padding-right: 23%;
    padding-bottom: 5%;
    border-bottom: 2px solid gray;
    @media (max-width: 991px) {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.pipelineSubHeading {
    font-family: 'Rubik';
    font-style: normal;
    font-size: 20px;
    line-height: 150%;
    color: #FCFCFC;
    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
    }
}

.statsDetails {

    background: #1A1A1A !important;
    border-radius: 40px;
    // border: 1px solid #FBF9D0;
}

.borderBox {
    // position: absolute;
    border: 1px solid #FBF9D0;
    border-radius: 40px;
}

.additiionalServiceText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #F2E854;
    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 1.2px;
    }
}

.survilenceberNumber {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-right: 10px !important;
    @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 1.5px;
    }
}

.survilenceHeading {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FCFCFC;
    text-align: left;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.survilenceDetails {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
    }
}

.borderClass {
    border-left: 1px solid #8EB274;
}

.mainDiv-1 {
    margin-top: 10%;

    .subDiv-1 {
        position: relative;
        border: 1px solid #8EB274;
        border-radius: 40px;
        background: #0D0D0D !important;

    }

    .subDiv-2 {
        position: relative;
        // border:1px solid yellow;
        border-radius: 40px;
        background: #1A1A1A;
    }
}

.graphXaxis {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FCFCFC;
}

.graphXAxisDiv {
    border-bottom: 1px solid rgba(142, 178, 116, 0.7);
}

.yAxisStatsname {
    border-right: 1px solid rgba(142, 178, 116, 0.7);
}

.yAxisDetail {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FCFCFC;
}