.email-card-body {
    border-bottom: 2px solid gray;
}

.email-card {
    border: 1px solid #F2E854;
    max-width: 505px;
    width: 100%;
    min-height: 214px;
    background-color: #242424;
    border-radius: 20px;
    @media (max-width: 400px) {
        max-width: 350px;
        width: auto;
    }
}


.email-address {
    ul li {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #FCFCFC;
        max-width: 265px;
        @media (max-width: 400px) {
            max-width: 130px;
            line-break: anywhere;
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.map-container {
    height: 100%;
    width: 100%;
    @media (max-width: 1023px) {
        min-height: 225px;
    }
}