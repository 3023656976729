.aboutContainer {
  background: #0d0d0d;
}
.leadershipHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #F2E854;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1.5px;
  }
}

.aboutContentDetails {
  border-bottom: 1px solid rgba(142, 178, 116, 0.7);
  padding-bottom: 50px;
  background-color: #0d0d0d;
  .flex {
    p {
      border-left: 1px solid rgba(142, 178, 116, 0.7);
      padding: 0 20px 0 !important;
      margin-left: 40px;
      @media (max-width: 767px) {
        margin-left: 16px;
      }
    }
    .heading {
      min-width: 70px;
    }
  }
}

.scientificBoardHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #f2e854;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1.5px;
  }
}

.DetailsDiv {
  background: url("../../images/iconsVector/whoWeAreAboutPage.svg");
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    .heading {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: 0.05em;
        text-align: left;
      }
    }
    .paragraph {
      font-family: "Rubik";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      @media (max-width: 767px) {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .whoWeAreDiv {
    padding-top: 5%;
    padding-bottom: 10%;
  }
}
