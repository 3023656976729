.cardContainer{
background: #1A1A1A !important;
height: 100%;
.nameContent{
    padding-top: 48px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 14px;
    @media (max-width: 767px) {
        padding-top: 20px;
    }
    .nameHeading{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #F2E854;
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    .leaderDesignation{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #FCFCFC;
        @media (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
}

.leaderDetails{
    
    max-height: 400px;
    overflow-y: scroll;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0 !important;
    p{
        padding-top: 10px !important;
    }
 
    

}
.scrollbar-container {
    /* Scrollbar Track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    /* Scrollbar Thumb */
    &::-webkit-scrollbar-thumb {
      background-color:rgba(160, 160, 160, 1) ;
    }
  
    /* Optional: Customize the scrollbar size */
    &::-webkit-scrollbar {
      width: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }

.nopopUPcardDetails{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0 !important;
}

.scrollable-element {
    scrollbar-color: red yellow !important;
  }


.popUpModal{
    background: #1A1A1A;
    
    
}

.popUpMainBackGround{
    background: rgba(61, 61, 61, 0.8);
    padding-top: 15%;
    padding-left: 30%;
    padding-right: 30%;
    padding-bottom: 15%;
}

.popUpName{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #F2E854;
}

.popUpDesignation{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FCFCFC;

}

.footerAdjust{
    padding-top: 40px ;
}

.leaderDetailsPopUP{
    padding-left: 40px;
    padding-right: 40px;
}

.imageDiv{
    padding-top: 57px;
}

// .modelHeight{
//     min-height: 796px !important;
//     max-height: 796px !important;

// }